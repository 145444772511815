$url_prefix: './assets/fonts/mallory';

@font-face {
   font-family: "Mallory Compact";
   font-style: normal;
   font-weight: 300;
   src: url('#{$url_prefix}/MalloryCmpct-Light.woff2') format('woff2'),
        url('#{$url_prefix}/MalloryCmpct-Light.woff2') format('woff');
}

@font-face {
   font-family: "Mallory Compact";
   font-style: italic;
   font-weight: 300;
   src: url('#{$url_prefix}/MalloryCmpct-LightItalic.woff2') format('woff2'),
        url('#{$url_prefix}/MalloryCmpct-LightItalic.woff2') format('woff');
}

@font-face {
   font-family: "Mallory Compact";
   font-style: normal;
   font-weight: 400;
   src: url('#{$url_prefix}/MalloryCmpct-Book.woff2') format('woff2'),
        url('#{$url_prefix}/MalloryCmpct-Book.woff2') format('woff');
}

@font-face {
   font-family: "Mallory Compact";
   font-style: italic;
   font-weight: 400;
   src: url('#{$url_prefix}/MalloryCmpct-BookItalic.woff2') format('woff2'),
        url('#{$url_prefix}/MalloryCmpct-BookItalic.woff2') format('woff');
}

@font-face {
   font-family: "Mallory Compact";
   font-style: normal;
   font-weight: 700;
   src: url('#{$url_prefix}/MalloryCmpct-Bold.woff2') format('woff2'),
        url('#{$url_prefix}/MalloryCmpct-Bold.woff2') format('woff');
}

@font-face {
   font-family: "Mallory Compact";
   font-style: italic;
   font-weight: 700;
   src: url('#{$url_prefix}/MalloryCmpct-BoldItalic.woff2') format('woff2'),
        url('#{$url_prefix}/MalloryCmpct-BoldItalic.woff2') format('woff');
}